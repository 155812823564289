<template>
  <div class="">
    <form autocomplete="off">
      <div class="custom-tab-card">
        <div class="my-card">
          <div class="row">
            <CustomSelectBox
              :className="'col-md-6'"
              :id="'employeeToken'"
              :value="jobAssignmentOrderData.employeeToken"
              :options="employeeTokenOptions"
              v-on:changeValue="jobAssignmentOrderData.employeeToken = $event"
              :title="$t('selectEmployee')"
              :imgName="'employees.svg'"
            />
            <CustomInput
              :className="'col-md-6'"
              :id="'jobAssignmentOrderCode'"
              :value="jobAssignmentOrderData.jobAssignmentOrderCode"
              :title="$t('jobAssignmentOrders.code')"
              :imgName="'number.svg'"
              v-on:changeValue="
                jobAssignmentOrderData.jobAssignmentOrderCode = $event
              "
            />
            <DatePicker
              :className="'col-md-6'"
              :id="'employeeJobAssignmentOrderStartDate'"
              :value="
                jobAssignmentOrderData.employeeJobAssignmentOrderStartDate
              "
              :title="$t('jobAssignmentOrders.assignmentStartDate')"
              :language="language"
              v-on:changeValue="
                jobAssignmentOrderData.employeeJobAssignmentOrderStartDate =
                  $event
              "
            />
            <TimePicker
              :className="'col-md-6'"
              :id="'employeeJobAssignmentOrderStartTime'"
              :value="
                jobAssignmentOrderData.employeeJobAssignmentOrderStartTime
              "
              v-on:changeValue="
                jobAssignmentOrderData.employeeJobAssignmentOrderStartTime =
                  $event
              "
              :title="$t('jobAssignmentOrders.assignmentStartTime')"
              :language="language"
            />
            <DatePicker
              :className="'col-md-6'"
              :id="'employeeJobAssignmentOrderEndDate'"
              :value="jobAssignmentOrderData.employeeJobAssignmentOrderEndDate"
              :title="$t('jobAssignmentOrders.assignmentEndDate')"
              :language="language"
              v-on:changeValue="
                jobAssignmentOrderData.employeeJobAssignmentOrderEndDate =
                  $event
              "
            />
            <TimePicker
              :className="'col-md-6'"
              :id="'employeeJobAssignmentOrderEndTime'"
              :value="jobAssignmentOrderData.employeeJobAssignmentOrderEndTime"
              v-on:changeValue="
                jobAssignmentOrderData.employeeJobAssignmentOrderEndTime =
                  $event
              "
              :title="$t('jobAssignmentOrders.assignmentEndTime')"
              :language="language"
            />
            <TextArea
              :className="'col-md-12'"
              :id="'jobAssignmentOrderDescriptionAr'"
              :value="jobAssignmentOrderData.jobAssignmentOrderDescriptionAr"
              :title="$t('jobAssignmentOrders.descriptionAr')"
              :imgName="'description.svg'"
              v-on:changeValue="
                jobAssignmentOrderData.jobAssignmentOrderDescriptionAr = $event
              "
            />
            <TextArea
              :className="'col-md-12'"
              :id="'jobAssignmentOrderDescriptionEn'"
              :value="jobAssignmentOrderData.jobAssignmentOrderDescriptionEn"
              :title="$t('jobAssignmentOrders.descriptionEn')"
              :imgName="'description.svg'"
              v-on:changeValue="
                jobAssignmentOrderData.jobAssignmentOrderDescriptionEn = $event
              "
            />
            <TextArea
              :className="'col-md-12'"
              :id="'jobAssignmentOrderDescriptionUnd'"
              :value="jobAssignmentOrderData.jobAssignmentOrderDescriptionUnd"
              :title="$t('jobAssignmentOrders.descriptionUnd')"
              :imgName="'description.svg'"
              v-on:changeValue="
                jobAssignmentOrderData.jobAssignmentOrderDescriptionUnd = $event
              "
            />
            <TextArea
              :className="'col-md-12'"
              :id="'jobAssignmentOrderNotes'"
              :value="jobAssignmentOrderData.jobAssignmentOrderNotes"
              :title="$t('notes')"
              :imgName="'notes.svg'"
              v-on:changeValue="
                jobAssignmentOrderData.jobAssignmentOrderNotes = $event
              "
            />
          </div>
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateJobAssignmentOrder"
        >
          {{ submitName }}
        </button>
        <router-link
          :to="{
            name: 'JobAssignmentOrders',
          }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { STATUS } from "@/utils/constants";
import Employees from "@/models/employees/employees/Employees";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";
import TextArea from "@/components/general/TextArea.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import DatePicker from "@/components/general/DatePicker.vue";
import TimePicker from "@/components/general/TimePicker.vue";

export default {
  name: "JobAssignmentOrderForm",
  mixins: [createToastMixin, currentTabMixin],
  components: {
    TextArea,
    CustomSelectBox,
    CustomInput,

    DatePicker,
    TimePicker,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employees: new Employees(),
      employeeTokenOptions: [],
    };
  },
  props: ["jobAssignmentOrderData", "submitName"],
  methods: {
    async getDialogOfEmployees() {
      this.isLoading = true;
      this.employeeTokenOptions = [];
      this.employeeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployee"),
      });
      try {
        const response = await this.employees.employee.getDialogOfEmployees(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },

    async addOrUpdateJobAssignmentOrder() {
      this.$emit("addOrUpdateJobAssignmentOrder");
    },
  },
  async created() {
    this.getDialogOfEmployees();
  },
};
</script>

<style scoped lang="scss"></style>
